import Menu from "./components/Menu/Menu";

import React, { useState, useEffect } from "react";
import { IonApp, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

// /* Optional CSS utils that can be commented out */
// import "@ionic/react/css/padding.css";
// import "@ionic/react/css/float-elements.css";
// import "@ionic/react/css/text-alignment.css";
// import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
// import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/theme.css";
import "./theme/print.css";
import CalendarPage from "./pages/Calendar/CalendarPage";
import { Provider } from "react-redux";

import configureStore from "./common/store";
import {
  FetchDrivers,
  FetchVehicles,
  FetchCustomers,
  FetchWorkSites,
  FetchAbsenceCauses,
} from "./helpers/Api";
import { GlobalActionTypes } from "./common/store/global/global.types";
import PlanningFormPage from "./pages/PlanningFormPage/PlanningFormPage";
import ModalConfiguration from "./components/ModalConfiguration/ModalConfiguration";

export const store = configureStore();

const App: React.FC = () => {
  const [selectedPage, setSelectedPage] = useState("");
  const [modalInit, setModalInit] = useState(false);

  useEffect(() => {
    init();
  }, [modalInit]);
  const init = async () => {
    const [baseId, appId] = await Promise.all([
      localStorage.getItem("AIRTABLE_BASE"),
      localStorage.getItem("AIRTABLE_API_KEY"),
    ]);
    if (baseId === null || appId === null) {
      setModalInit(true);
      return;
    }

    // TODO move to redux thunk action
    const [
      drivers,
      vehicles,
      customers,
      workSites,
      absenceCauses,
    ] = await Promise.all([
      FetchDrivers(),
      FetchVehicles(),
      FetchCustomers(),
      FetchWorkSites(),
      FetchAbsenceCauses(),
    ]);
    await Promise.all([
      store.dispatch({
        type: GlobalActionTypes.FETCH_DRIVERS,
        payload: drivers,
      }),
      store.dispatch({
        type: GlobalActionTypes.FETCH_VEHICLES,
        payload: vehicles,
      }),
      store.dispatch({
        type: GlobalActionTypes.FETCH_CUSTOMERS,
        payload: customers,
      }),
      store.dispatch({
        type: GlobalActionTypes.FETCH_WORKSITES,
        payload: workSites,
      }),
      store.dispatch({
        type: GlobalActionTypes.FETCH_ABSENCECAUSES,
        payload: absenceCauses,
      }),
    ]);

    await store.dispatch({
      type: GlobalActionTypes.APP_INIT_COMPLETE,
    });
  };
  return (
    <IonApp>
      <Provider store={store}>
        <IonReactRouter>
          <IonSplitPane contentId="main" disabled={true}>
            <Menu selectedPage={selectedPage} />
            <IonRouterOutlet id="main">
              <Route path="/calendar" component={CalendarPage} exact={true} />
              <Route
                path="/calendar/new"
                component={PlanningFormPage}
                exact={true}
              />
              <Route
                path="/"
                render={() => <Redirect to="/calendar" />}
                exact={true}
              />
            </IonRouterOutlet>
            <ModalConfiguration
              showModal={modalInit}
              setModalInit={setModalInit}
            />
          </IonSplitPane>
        </IonReactRouter>
      </Provider>
    </IonApp>
  );
};

export default App;
