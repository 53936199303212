import React from "react";

import dayjs from "dayjs";
import DailyView from "./DailyView";
import { IonList } from "@ionic/react";
import DayLabel from "./DayLabel";

type WeeklyViewProps = {
  weeklyPlanning: any[];
  loadingStatus: boolean;
  currentDate: any;
};

const WeeklyView: React.FunctionComponent<WeeklyViewProps> = ({
  weeklyPlanning,
  loadingStatus,
  currentDate,
}) => {
  const getDayLabelByIndex = (index: number) => {
    return dayjs(dayjs(currentDate).weekday(index)).format("dddd DD");
  };

  return (
    <div className="week-container">
      {weeklyPlanning.map((day, index) => {
        if (day.length === 0 && index === 5) return null;
        else
          return (
            <div className="day-container" key={index}>
              <IonList>
                <DayLabel
                  dayLabel={getDayLabelByIndex(index)}
                  dayIndex={index}
                  hasRecords={Boolean(day.length)}
                />

                <DailyView
                  dailyPlanning={day}
                  loadingStatus={loadingStatus}
                  dayIndex={index}
                />
              </IonList>
            </div>
          );
      })}
    </div>
  );
};
export default WeeklyView;
