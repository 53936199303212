import React, { useCallback } from "react";
import { IonLabel, IonItemDivider, IonIcon, isPlatform } from "@ionic/react";
import { duplicateOutline } from "ionicons/icons";
import { useHover } from "../../common/hooks/useHover.hook";
import { useDispatch } from "react-redux";
import { toggleDuplicationMode } from "../../common/store/calendar/calendar.actions";

type DayLabelProps = {
  dayLabel: string;
  dayIndex: number;
  hasRecords: boolean;
};

const DayLabel: React.FunctionComponent<DayLabelProps> = ({
  dayLabel,
  dayIndex,
  hasRecords,
}) => {
  const [hovered, bindHover] = useHover();

  const dispatch = useDispatch();

  const isTouchDevice = () => {
    return isPlatform("mobile") || isPlatform("tablet");
  };

  const onClickDuplicateButton = useCallback(() => {
    dispatch(toggleDuplicationMode(dayIndex, true));
  }, []);
  return (
    <IonItemDivider sticky={true} {...bindHover}>
      <IonLabel className="day-label">{dayLabel}</IonLabel>
      {(hovered || isTouchDevice()) && hasRecords && (
        <IonIcon
          icon={duplicateOutline}
          onClick={onClickDuplicateButton}
          style={{ position: "absolute", right: 0, cursor: "pointer" }}
        />
      )}
    </IonItemDivider>
  );
};
export default DayLabel;
