import { IonChip, IonLabel, IonCol, IonIcon } from "@ionic/react";
import React from "react";

import { RootState } from "../../common/store";
import { useDispatch, useSelector } from "react-redux";
import { CalendarState } from "../../common/store/calendar/calendar.reducer";
import {
  setPlanningFilters,
  toggleFilterPanel,
} from "../../common/store/calendar/calendar.actions";
import "./PlanningFilters.scss";
import { funnelOutline, funnelSharp } from "ionicons/icons";

type PartialCalendarState = Pick<
  CalendarState,
  "filters" | "isFilterPanelToggled"
>;

interface PlanningFiltersProps {}
const PlanningFilters: React.FunctionComponent<PlanningFiltersProps> = () => {
  const dispatch = useDispatch();
  const { filters, isFilterPanelToggled }: PartialCalendarState = useSelector(
    (state: RootState) => state.calendar
  );
  return (
    <>
      <IonCol size={"4"}>
        <IonChip
          color="dark"
          outline={!filters.booking}
          onClick={() => {
            dispatch(setPlanningFilters({ booking: !filters.booking }));
          }}
        >
          <IonLabel>Réservation</IonLabel>
        </IonChip>

        <IonChip
          onClick={() => {
            dispatch(toggleFilterPanel(!isFilterPanelToggled));
          }}
          outline={
            !isFilterPanelToggled &&
            filters.customers.length === 0 &&
            filters.drivers.length === 0
          }
        >
          <IonLabel>Filtrer</IonLabel>
          <IonIcon
            icon={
              !isFilterPanelToggled &&
              filters.customers.length === 0 &&
              filters.drivers.length === 0
                ? funnelOutline
                : funnelSharp
            }
          />
        </IonChip>
      </IonCol>
    </>
  );
};

export default PlanningFilters;
