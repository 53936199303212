export const API_URL = "";

export const MISSILLIER_TP_CUSTOMER_ID =
  process.env.REACT_APP_MISSILLIER_TP_CUSTOMER_ID;
export const ABSENT_CUSTOMER_ID = process.env.REACT_APP_ABSENT_CUSTOMER_ID;

export const ClientColors = {
  Absent: "#f82b60",
  "Missillier TP": "#F37020",
  "Colas - Perrignier": "#AAAAAA",
  "Colas - Annecy": "#AAAAAA",
  Colas: "#444444",
  Soupe: "#ba1e45",
  Dépôt: "#FF638B",
  Mécanique: "#FF638B",
  Bureau: "#FF638B",
  Decharge: "#FF638B",
  Formation: "#FF638B",
  Deneigement: "#FF638B",
  Repos: "#FF638B",
  Veolia: "#ffd66e",
  Derichebourg: "#F8B200",
  Eurovia: "#1E7A00",
  Rampa: "#6b1cb0",
  Eiffage: "#63D33E",
  Guintoli: "#ff08c2",
  GrandJacques: "#18bfff",
};

export const PlanningEntryOptions = [
  "1/2 Jour",
  "Ampliroll",
  "Autoroute",
  "Benne supplémentaire",
  "Décharge",
  "Gex",
  "Gravier 0.20",
  "Gravier 0.80",
  "Gravier Autre",
  "H sup",
  "Journée Incomplète",
  "Nuit",
  "Passy",
];
