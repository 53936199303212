export enum GlobalActionTypes {
  APP_INIT_COMPLETE = "@@global/APP_INIT_COMPLETE",
  FETCH_DRIVERS = "@@global/FETCH_DRIVERS",
  FETCH_VEHICLES = "@@global/FETCH_VEHICLES",
  FETCH_CUSTOMERS = "@@global/FETCH_CUSTOMERS",
  FETCH_WORKSITES = "@@global/FETCH_WORKSITES",
  FETCH_ABSENCECAUSES = "@@global/FETCH_ABSENCECAUSES",
}

interface FetchDriversAction {
  type: GlobalActionTypes.FETCH_DRIVERS;
  payload: any[];
}
interface FetchVehiclesAction {
  type: GlobalActionTypes.FETCH_VEHICLES;
  payload: any[];
}

interface FetchCustomersAction {
  type: GlobalActionTypes.FETCH_CUSTOMERS;
  payload: any[];
}
interface FetchWorksitesAction {
  type: GlobalActionTypes.FETCH_WORKSITES;
  payload: any[];
}

interface FetchAbsenceCausesAction {
  type: GlobalActionTypes.FETCH_ABSENCECAUSES;
  payload: any[];
}

export type GlobalAction =
  | FetchDriversAction
  | FetchVehiclesAction
  | FetchCustomersAction
  | FetchWorksitesAction
  | FetchAbsenceCausesAction;
