import { IonRow } from "@ionic/react";
import React from "react";
import { useSpring, animated, config } from "react-spring";

import { RootState } from "../../common/store";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { setPlanningFilters } from "../../common/store/calendar/calendar.actions";
import "./PlanningFiltersDisplay.scss";
import { GlobalState } from "../../common/store/global/global.reducer";
import { CalendarState } from "../../common/store/calendar/calendar.reducer";

type PlanningFiltersDisplayProps = {
  forceToggle?: boolean;
};

type PartialCalendarState = Pick<
  CalendarState,
  "filters" | "isFilterPanelToggled"
>;
type PartialGlobalState = Pick<
  GlobalState,
  "drivers" | "vehicles" | "customers"
>;
const PlanningFiltersDisplay: React.FunctionComponent<PlanningFiltersDisplayProps> = ({
  forceToggle = false,
}) => {
  const dispatch = useDispatch();
  const { filters, isFilterPanelToggled }: PartialCalendarState = useSelector(
    (state: RootState) => state.calendar
  );
  const { customers, drivers, vehicles }: PartialGlobalState = useSelector(
    (state: RootState) => state.global
  );

  let isFilterPanelDisplayed = false;
  if (forceToggle || isFilterPanelToggled) isFilterPanelDisplayed = true;

  const contentProps = useSpring({
    from: { opacity: 0, marginTop: -60 },
    opacity: isFilterPanelDisplayed ? 1 : 0,
    marginTop: isFilterPanelDisplayed ? 0 : -60,
    config: config.molasses,
  });

  return (
    <animated.div className="filter-container" style={contentProps}>
      <p className="filter-by">Filtrer par</p>
      <IonRow className="filter-values-display ion-align-items-center">
        <Select
          closeMenuOnSelect={false}
          placeholder="Clients"
          isMulti
          styles={customStyles}
          options={customers.map((customer: any) => ({
            value: customer.id,
            label: customer.fields.Client,
            color: customer.fields.Couleur,
          }))}
          className="filter-select"
          onChange={(e: any) => {
            dispatch(setPlanningFilters({ customers: e }));
          }}
          value={filters.customers}
        />
        <Select
          closeMenuOnSelect={false}
          className="filter-select"
          placeholder="Chauffeurs"
          isMulti
          options={drivers.map((driver: any) => ({
            value: driver?.id,
            label: driver?.fields?.Prénom,
          }))}
          onChange={(e: any) => {
            dispatch(setPlanningFilters({ drivers: e }));
          }}
          value={filters.drivers}
        />

        <Select
          closeMenuOnSelect={false}
          className="filter-select"
          placeholder="Matériels"
          isMulti
          options={vehicles.map((vehicle: any) => ({
            value: vehicle?.id,
            label: vehicle?.fields?.Identifiant,
          }))}
          onChange={(e: any) => {
            dispatch(setPlanningFilters({ vehicles: e }));
          }}
          value={filters.vehicles}
        />
      </IonRow>
    </animated.div>
  );
};

export default PlanningFiltersDisplay;

const customStyles = {
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: `#${data.color}` || "#000",
    };
  },
};
